import React from 'react';
import parse from 'html-react-parser';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

const parseHtml = (htmlString) => {
  const handleLinkClick = (linkText, path) => {
    reportToSegment(types.TRACK, eventNames.linkClicked, {
      section: 'article',
      style: 'inline',
      text: linkText,
      path,
    });
  };
  return parse(htmlString, {
    replace: (domNode) => {
      if (domNode.name === 'a') {
        return (
          <a
            href={domNode.attribs.href}
            onClick={() => handleLinkClick(domNode.children[0].data, domNode.attribs.href)}
          >
            {domNode.children[0].data}
          </a>
        );
      }
      return domNode;
    },
  });
};

export default parseHtml;
